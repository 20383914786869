<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <mdb-container fluid>
          <h1>{{$t('scheduleslist.scheduleslist')}}</h1>
          <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
          <mdb-row v-if="schedules.length">
            <mdb-col md="12">
              <mdb-switch :onLabel="$t('scheduleslist.showhiddenschedules')" offLabel="" v-model="displayconfig.showhidden" />
              <mdb-switch :onLabel="$t('scheduleslist.showpasschedules')" offLabel="" v-model="displayconfig.showpast" />
              <hr />
            </mdb-col>
          </mdb-row>
          <mdb-row v-if="schedules.length">
            <mdb-col md="12">
              <mdb-tbl sm>
                <mdb-tbl-head>
                  <tr>
                    <th>{{$t('scheduleslist.operatingdates')}}</th>
                    <th>{{$t('scheduleslist.gateways')}}</th>
                    <th>{{$t('scheduleslist.flightnum')}}</th>
                    <th>{{$t('scheduleslist.operatingdays')}}</th>
                    <th v-if="displayconfig.showhidden">{{$t('scheduleslist.hidden')}}</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr v-for="(schedule, index) in orderedschedules" v-bind:key="index">
                    <td>{{formatDate(schedule.startdate)}} au {{formatDate(schedule.enddate)}}</td>
                    <td>{{schedule.origin}} ➡ {{schedule.destination}}</td>
                    <td>{{schedule.flightnum}}</td>
                    <td>{{getDaysOfOperation(schedule)}}</td>
                    <td v-if="displayconfig.showhidden"><mdb-switch :onLabel="$t('scheduleslist.hidden')" offLabel="" v-model="schedule.ishidden" v-on:getValue="togglehidden(schedule.id, $event)" /></td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbSwitch,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'schedulesList',
  data() {
    return {
      error: null,
      schedules: [],
      displayconfig: {
        showhidden: false,
        showpast: false,
      },
      currentscheduleid: 0,
    };
  },
  computed: {
    orderedschedules() {
      const sorted = [];
      this.schedules.forEach((schedule) => {
        let canshow = true;
        if (!this.displayconfig.showhidden) {
          if (schedule.ishidden) {
            canshow = false;
          }
        }
        if (!this.displayconfig.showpast) {
          if (new Date(schedule.enddate).getTime() < new Date().getTime()) {
            canshow = false;
          }
        }
        if (canshow) {
          sorted.push(schedule);
        }
      });

      function compare(a, b) {
        if (new Date(a.startdate).getTime() < new Date(b.startdate).getTime()) {
          return -1;
        }
        if (new Date(a.startdate).getTime() > new Date(b.startdate).getTime()) {
          return 1;
        }
        return 0;
      }

      return sorted.sort(compare);
    },
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbSwitch,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  mounted() {
    this.loadSchedules();
  },
  methods: {
    loadSchedules() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/flight/get-schedules').then((response) => {
        if (response.status === 'OK') {
          self.schedules = response.data.schedules;
        } else {
          self.error = response.msg;
        }
      });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getDaysOfOperation(_schedule) {
      const toreturn = [];
      if (_schedule.sunday) {
        toreturn.push(this.$t('syncwithdarwin.sunday'));
      }
      if (_schedule.monday) {
        toreturn.push(this.$t('syncwithdarwin.monday'));
      }
      if (_schedule.tuesday) {
        toreturn.push(this.$t('syncwithdarwin.tuesday'));
      }
      if (_schedule.wednesday) {
        toreturn.push(this.$t('syncwithdarwin.wednesday'));
      }
      if (_schedule.thursday) {
        toreturn.push(this.$t('syncwithdarwin.thursday'));
      }
      if (_schedule.friday) {
        toreturn.push(this.$t('syncwithdarwin.friday'));
      }
      if (_schedule.saturday) {
        toreturn.push(this.$t('syncwithdarwin.saturday'));
      }
      return toreturn.join('<br />');
    },
    togglehidden(id, event) {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/schedule-toggle-visibility', { scheduleid: id, ishidden: event }).then(() => {
        self.loadSchedules();
      });
    },
  },
};
</script>
